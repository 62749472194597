import { type RouteObject } from 'react-router-dom'
import { ALERTS_HOME_PAGE } from './Alerts.navigator'
import { AlertsPage } from './Alerts.page'

export const alertsRoutes: RouteObject[] = [
  {
    id: '(alerts) Main Page - Container list',
    path: ALERTS_HOME_PAGE,
    element: <AlertsPage/>
  }
]
