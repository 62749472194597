import {AtlasBodySubtitle, CardComponent, CardRow, StackAtom, AtlasBodyMain, ImageThumbnailComponent} from "@justomx/atlas-components";
import { useTranslation } from "react-i18next";
import TareImg from '../../../assets/images/Utilities/Illustration_tara.svg'
import TareWithPriorityImg from '../../../assets/images/Utilities/Illustration_tara_with_priority.svg'

export type TareInfoProps = {
  id: string;
  orderId: string;
  priority: number;
}

const TareInfo = ({id, orderId, priority}: TareInfoProps) => {
  const {t} = useTranslation('global')
  const img = priority !== 4 ? TareWithPriorityImg : TareImg
  return <CardComponent>
    <CardRow style={{alignItems: 'flex-start'}}>
    <ImageThumbnailComponent 
      size={60}
      src={img}
      style={{ alignSelf: 'self-start' }}
    />
    <StackAtom gap={12}>
      <AtlasBodySubtitle>{t("reverse-logistics.tare-id")} {id}</AtlasBodySubtitle>
      <AtlasBodyMain>{t("reverse-logistics.order")} {orderId}</AtlasBodyMain>
    </StackAtom>
  </CardRow>
  </CardComponent>;
}

export default TareInfo;

