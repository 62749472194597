import {
  AtlasBodySubtitle,
  AtlasColor,
  AtlasH2,
  BottomGroupMolecule,
  CardComponent,
  CardRow,
  Icon,
  Icons,
  PageContentAtom,
  PrimaryButtonAtom,
  SnackbarInformationPanel,
  SpacerAtom,
  TextBoxMolecule,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { FlexColumnAtom } from 'components/atoms/FlexColumn.atom'
import LoaderAtom from 'components/atoms/Loader.atom'
import { CheckboxMolecule } from 'components/molecules/Checkbox.molecule'
import { FormikTextFieldOrganism } from 'components/organisms/FormikTextField.organism'
import { ErrorMessage, Form, FormikProvider } from 'formik'
import { useTranslation } from 'react-i18next'
import { type Product } from 'types/model'
import { ProductCardMolecule } from '../../../components/molecules/ProductCard.molecule'
import { wasteReasons } from './RegisterWaste.helpers'
import { useInventoryWasteRegister } from './RegisterWaste.hooks'
import { FlexRowAtom } from 'components/atoms/FlexRow.atom'
import { useState } from 'react'
import { formatWeight } from 'utils/product'
import { DeleteIcon } from 'components/DeleteIcon'
import { formatString } from 'utils/string'
import { ConfirmationDialogMolecule, useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'
import { onErrorToast } from '../../../onErrorToast.util'

interface ChangeWeightMoleculeProps {
  label: string
  onAdd: (value: number) => void
}

const ChangeWeightMolecule = ({
  label,
  onAdd
}: ChangeWeightMoleculeProps) => {
  const { t } = useTranslation('global')

  const [qty, setQty] = useState(0)

  const addWeightClick = (e) => {
    e.preventDefault()
    onAdd(qty)
  }

  return (
    <CardRow>
      <FlexRowAtom style={{
        width: '100%',
        alignItems: 'stretch',
        gap: '1em'
      }}>
        <TextBoxMolecule type="number" label={label} onChange={(it) => {
          setQty(Number(it))
        }} />
        <PrimaryButtonAtom style={{
          height: 'auto',
          color: AtlasColor.Primary00,
          backgroundColor: `${AtlasColor.Primary00}22`
        }} onClick={addWeightClick}>
          {t('fast-set-weight.btn-add')}
        </PrimaryButtonAtom>
      </FlexRowAtom>
    </CardRow>
  )
}

const WeighableErrorMessage = () => {
  return <ErrorMessage name="amount">
    {msg => (
      <SnackbarInformationPanel style={{
        marginTop: '12px',
        color: '#FF6D76'
      }}>
        <Icon src={Icons.IcInformationIcon} style={{ color: '#FF6D76' }} />
        {msg}
      </SnackbarInformationPanel>
    )}
  </ErrorMessage>
}

interface ListOfWeightsProps {
  detail: string
  items: number[]
  onDelete: (item: number) => void
}

const ListOfWeights = ({
  detail,
  items,
  onDelete
}: ListOfWeightsProps) => {
  return (
    <>
      <AtlasBodySubtitle>{detail}</AtlasBodySubtitle>
      <WeighableErrorMessage />
      <SpacerAtom height={10} />
      {
        items.map((it, i) => {
          return (
            <>
              <CardComponent key={`${i}_${it}`}>
                <CardRow>
                  <FlexRowAtom style={{ width: '100vw' }}>
                    <div style={{
                      flex: 1,
                      paddingRight: '1em'
                    }}>{formatWeight(it)}</div>
                    <div style={{
                      color: AtlasColor.Primary00,
                      cursor: 'pointer'
                    }}
                         onClick={() => {
                           onDelete(it)
                         }}>
                      <DeleteIcon />
                    </div>
                  </FlexRowAtom>
                </CardRow>
              </CardComponent>
              <SpacerAtom height={10} />
            </>
          )
        })
      }
    </>
  )
}

export const RegisterWastePage = () => {
  const { t } = useTranslation('global')
  const {
    actions,
    product,
    formConfig,
    loading,
    weights,
    totalWeights
  } = useInventoryWasteRegister(onErrorToast)
  const {
    dialogRef: deleteConfirmationRef,
    open: openDeleteDialog,
    value: valueToDelete
  } = useConfirmationDialog<number>()

  const isWeighable = product?.byWeight ?? false

  return <>
    {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}
    <ToolbarMolecule
      title={t('inventory-waste.register-waste.title')}
      actionIcon={Icons.ArrowBack}
      onAction={actions.goBack}
    />
    <PageContentAtom>
      <ProductCardMolecule
        onClick={() => {
        }}
        product={product as unknown as Product}
        showStock={true}
      />
      <FormikProvider value={formConfig}>
        <Form onSubmit={formConfig.handleSubmit}>
          <SpacerAtom height={10} />
          <AtlasH2>
            {t(isWeighable ? 'inventory-waste.register-waste.quantity-title-weighable' : 'inventory-waste.register-waste.quantity-title')}
          </AtlasH2>
          <SpacerAtom height={30} />
          {
            !isWeighable
              ? <FlexColumnAtom $gap={32}>
                <FormikTextFieldOrganism
                  type="number"
                  name="amount"
                  label={t('inventory-waste.register-waste.units')}
                  formConfig={formConfig}
                />
              </FlexColumnAtom>
              : <>
                <ChangeWeightMolecule
                  onAdd={(value) => {
                    actions.addItem(value)
                  }}
                  label={t('inventory-waste.register-waste.grams')}
                />
                <SpacerAtom height={30} />
                <ListOfWeights
                  detail={formatString(t('inventory-waste.register-waste.total-qty'), formatWeight(totalWeights, 2))}
                  items={weights}
                  onDelete={openDeleteDialog}
                />
                <input
                  type="number"
                  name="amount"
                  value={formConfig.values.amount}
                  onChange={formConfig.handleChange}
                  style={{ display: 'none' }}
                />
              </>
          }
          <SpacerAtom height={30} />
          <AtlasH2>
            {t('inventory-waste.register-waste.reason-title')}
          </AtlasH2>
          <SpacerAtom height={30} />
          <FlexColumnAtom>
            {wasteReasons(product).map((reason) => (
              <CheckboxMolecule
                key={reason}
                name="reason"
                value={reason}
                checked={formConfig.values.reason.includes(reason)}
                label={t(`inventory-waste.register-waste.reasons.${reason}`)}
                onChange={formConfig.handleChange}
              />
            ))}
          </FlexColumnAtom>

          <SpacerAtom height={100} />
          <BottomGroupMolecule>
            <PrimaryButtonAtom type="submit" disabled={!formConfig.isValid}>
              {t('receipt-rejection.register')}
            </PrimaryButtonAtom>
          </BottomGroupMolecule>
        </Form>
      </FormikProvider>
      <ConfirmationDialogMolecule dialogRef={deleteConfirmationRef}
                                  value={valueToDelete}
                                  confirmText={t('fast-set-weight.dialog-delete.btn-confirm')}
                                  onConfirm={actions.deleteItem}
                                  cancelText={t('fast-set-weight.dialog-delete.btn-cancel')}>
        {
          valueToDelete
            ? formatString(t('fast-set-weight.dialog-delete.msg'), formatWeight(valueToDelete))
            : ''
        }
      </ConfirmationDialogMolecule>
    </PageContentAtom>
  </>
}
