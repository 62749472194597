import { isProduction } from '../utils/environment'

const stgToken = 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NTM2LCJzZWN1cml0eUNvZGUiOiIiLCJpYXQiOjE3MTI2ODUxMTAsImV4cCI6MTcyODIzNzExMCwiYXVkIjoiaHR0cHM6Ly93d3cua292aXguY28vIiwiaXNzIjoiS292aXgiLCJzdWIiOiJLb3ZpeCBDbGllbnQifQ.CfxH-YhSaFKbIlvsFDMWZ332JTX8NbC0BxHyiXQrQzAhfMOF3FRlZPrvY_iVpaXQEt5fCncPHqe9MXlWkmgOtVRciMd6vPdNKM5yHP3Vs9dkSgfaN8Pp5eIXAnhJRwQfoVGn9zGj_eL2uLV24dUk_yMSujFaOqY5m2r7h5lMFMBDiD9CLlPtlg1r1mLUXcfuSQ3bdlG8_tyBpnQDHkQrhlaxXKYI3JXD5TRzeh0vHyZFZZRRSh8ks7_PZkP9uoI6fBNL7Xn83mmrgDpdJvmggKtLTsmyAFCnDBV6CqRaeKTC049gwTQLg2ZE2cai9rZ7B6Gk7jpKebW7_mlmhaRowpqbQ4gdo5gFztpke1uaFnrV_IDMkYMZCKwXm6NSvgUwlR5YgoUDWT1OEYi1H4ilWfzTan6UeKUq4V6uPDukMRyV4rEWnId0rMKoW8zzCXd1ihwcYBnvDyKUC0UUHxqSZDvGoywLAONwTk9J66qEeVmu8VlJJ3u0KbEwdM5i-LChW5kNVBfz-JTkqKZ6gScQSf0_wApsP4oaeyk6lzEoJ9e6fOKilwuI1Pona1haqfE8NpBye0kwhbT8AlWqiqmJUXoj0n8NCIr7mpzQTrE6rI3UppLy16cEJsVZsW38UBdFJZILaL5-R4Dpd1k4GTdshpi2RG3aCFWf4elDFwsijyA'

export interface CommonHeaders {
  'x-hardcoded': boolean
  authorization: string
  'x-authorization-wms': string
  'x-authorization-core': string
  'x-justo-country': string
  'x-justo-warehouse'?: string
}

export const getCommonHeaders = (): CommonHeaders => {
  const metadata = (window as any)?.WVMetadata
  if (metadata) {
    return {
      'x-hardcoded': false,
      authorization: metadata['x-authorization-wms'],
      'x-authorization-wms': metadata['x-authorization-wms'],
      'x-authorization-core': metadata['x-authorization-core'],
      'x-justo-country': metadata['x-justo-country']?.toUpperCase(),
      'x-justo-warehouse': metadata['x-justo-warehouse']?.toUpperCase()
    }
  }
  if (isProduction()) {
    console.log('Metadata of WV is undefined, using default values.')
  }
  return {
    'x-hardcoded': true,
    authorization: stgToken,
    'x-authorization-wms': stgToken,
    'x-authorization-core': stgToken,
    'x-justo-country': 'MX',
    'x-justo-warehouse': 'PP'
  }
}

export function getCurrentCountry () {
  const metadata = (window as any)?.WVMetadata
  if (metadata) {
    return metadata['x-justo-country']?.toUpperCase()
  }
  if (isProduction()) {
    console.log('Metadata of WV is undefined, using default values.')
  }
  return 'MX'
}

export function getCurrentWarehouse () {
  const metadata = (window as any)?.WVMetadata
  if (metadata) {
    return metadata['x-justo-warehouse']?.toUpperCase()
  }
  if (isProduction()) {
    console.log('Metadata of WV is undefined, using default values.')
  }
  return 'PP'
}
