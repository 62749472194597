import { normalizeEanForCapture } from 'types/model'
import { scannerAxiosInstance } from '../../utils/op-scanner.axios.instance'
import { getCurrentCountry } from '../utilities.client-helper'
import {
  isScannedContainer,
  isScannedLocation,
  ScannedSupply,
  type ScannedContainer,
  type ScannedLocation,
  type ScannedProduct,
  type ScanResponse
} from './scanner.types'

export const getScannedInfo = async (scanned: string): Promise<ScanResponse> => {
  return await scannerAxiosInstance()
    .get<ScanResponse>(`/scan/${scanned}`)
    .then(e => e.data)
}

const getProductFromScanRaw = async (url: string): Promise<ScannedProduct> => {
  try{
    return await scannerAxiosInstance().get<ScannedProduct>(`${url}?types=Product`).then(e => e.data)
  }
  catch(e){
    throw new Error('Scanned data is not a product')
  }
}

export const getProductFromScan = async (scanned: string): Promise<ScannedProduct> => {
  const normalizedEan = normalizeEanForCapture(scanned)
  const normalizedUrl = `/scan/${normalizedEan}`
  const rawUrl = `/scan/${scanned}`;

  return await getProductFromScanRaw(normalizedUrl)
    .catch(e => {
      if (e.response?.status === 404) {
        return getProductFromScanRaw(rawUrl);
      }
      throw e;
    })
}

export const getContainerFromScan = async (scanned: string): Promise<ScannedContainer> => {
  try{
    const { data } = await scannerAxiosInstance().get(`/scan/${scanned}?types=Container`)
    return data
  }
  catch(e){
    throw new Error('Scanned data is not a container')
  }
}

export const getLocationFromScan = async (scanned: string): Promise<ScannedLocation> => {
  try{
    const { data } = await scannerAxiosInstance().get(`/scan/${scanned}?types=Location`)
    return data   
  }
  catch(e){
    throw new Error('Scanned data is not a location')
  }
}

export const getLocationOrContainerFromScan = async (scanned: string): Promise<ScannedLocation | ScannedContainer> => {
  try{
    const { data } = await scannerAxiosInstance().get(`/scan/${scanned}?types=Location&types=Container`)
    return data
  }
  catch(e){
    throw new Error('Scanned data is not a location or container')
  }
}

export const getSupplyFromScan = async (scanned: string): Promise<ScannedSupply> => {
  try{
    const { data } = await scannerAxiosInstance().get(`/scan/${scanned}?types=Supply`)
    return data
  }
  catch(e){
    throw new Error('Scanned data is not a supply')
  }
}


export const getProductOrSupplyFromScan = async (scanned: string): Promise<ScannedProduct | ScannedSupply> => {
  try{
    const { data } = await scannerAxiosInstance().get(`/scan/${scanned}?types=Supply&types=Product`)
    return data
  }
  catch(e){
    throw new Error('Scanned data is not a supply or product')
  }
}

export const isScannerEndpointsOn = () => {
  return getCurrentCountry() === 'PE'
}
