import { useScanner } from '../../../hooks/scanner.hook'
import { useGoToMoveOptions } from '../move-options/MoveOptions.navigator'
import { useEffect } from 'react'
import { Global } from '../../../utils/global'
import { getInventoryProductsClient, getLocationOrContainerByLastNumbers } from '../../../client/utilities.client'
import { manageError } from '../../../utils/errors/error.handler'
import { validateOrigin } from './ScanOrigin.validator'
import { useGoToChooseContainer } from '../choose-container/ChooseContainer.navigator'
import { useAtom } from 'jotai'
import { traslationOriginInfoAtom } from '../Traslation.state'
import { getLocationOrContainerFromScan } from 'client/scanner/scanner.client'

export const useScanOrigin = () => {
  const { keys, getScan } = useScanner()
  const moveOptions = useGoToMoveOptions()
  const chooseContainer = useGoToChooseContainer()
  const [_, setLocationState] = useAtom(traslationOriginInfoAtom)

  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      getLocationOrContainerFromScan(scanned)
        .then(origin => getInventoryProductsClient(origin.location.legacyId, origin.container?.legacyId ,true).then(looseProducts => ({ origin, looseProducts })))
        .then(({origin, looseProducts}) => validateOrigin(origin, looseProducts.length > 0))
        .then(origin => {
          setLocationState(origin)
          if (origin.container?.id == null && origin.totalContainers && origin.totalContainers > 1) {
            chooseContainer.go(origin)
          } else {
            moveOptions.go(origin)
          }
        })
        .catch(manageError(`ScanOrigin.hooks.ts#useEffect ${scanned}`))
    }
  }, [keys])

  return {
    actions: {
      openMenu: () => { Global.WV.openMenu() },
      goToContainerByLastNumbers: (lastNumbers: string) => {
        getLocationOrContainerByLastNumbers(lastNumbers)
          .then(moveOptions.go)
          .catch(manageError(`ScanOrigin.hooks.ts#actions.goToContainerByLastNumbers ${lastNumbers}`))
      }
    }
  }
}
