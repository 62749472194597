import { toast } from 'react-toastify'
import { Icons, PageContentAtom, PrimaryButtonAtom, ToolbarMolecule } from '@justomx/atlas-components'
import { SCAN_CONTAINER_LOCATION } from '../../inventory-fix/scan-container-location/ScanContainerLocation.navigator'
import { PrimaryLinkAtom } from '../../../components/atoms/PrimaryLink.atom'
import { FAST_TRASLATION_ROUTE } from '../../fast-traslation/FastTraslation.navigator'
import { CONTAINER_ADMINISTRATION } from 'pages/container-administration/ContainerAdministration.navigator'
import { ALERTS_HOME_PAGE } from 'pages/alerts/Alerts.navigator'
import { TRASLATION_SCAN_ORIGIN } from 'pages/traslation/scan-origin/ScanOrigin.navigator'
import { useTranslation } from 'react-i18next'
import { STORAGE_URL } from "../../storage/Storage.navigator";
import { RECEIPT_URL } from 'pages/receipt/Receipt.navigator'
import { INVENTORY_WASTE_SCAN_ORIGIN } from 'pages/inventory-waste/scan-origin/ScanOrigin.navigator'
import { INVENTORY_TRANSFER_URL } from 'pages/inventory-transfer/InventoryTransfer.navigator'

import './HomeList.page.css'
import { UNIVERSAL_SEARCH_URL } from 'pages/universal-search/universal-search.navigator'
import { REVERSE_LOGISTICS_URL } from 'pages/reverse-logistics/ReverseLogistics.navigator'

export const HomeListPage = () => {
  const { t, i18n } = useTranslation('global')
  return (
    <>
      <ToolbarMolecule title='Picking'
                        actionIcon={Icons.ArrowBack}
                        onAction={() => toast('Principal action!')} />
      <PageContentAtom>
        <div className="language-zone">
          <PrimaryButtonAtom onClick={async () => await i18n.changeLanguage('es')}>
            {t('home.btn-use-es')}
          </PrimaryButtonAtom>

          <PrimaryButtonAtom onClick={async () => await i18n.changeLanguage('pt')}>
            {t('home.btn-use-br')}
          </PrimaryButtonAtom>
        </div>

        <PrimaryLinkAtom to={TRASLATION_SCAN_ORIGIN}>{t('home.link-transfer-inventory')}</PrimaryLinkAtom>
        <PrimaryLinkAtom to={SCAN_CONTAINER_LOCATION}>{t('home.link-fix-inventory')}</PrimaryLinkAtom>
        <PrimaryLinkAtom to={FAST_TRASLATION_ROUTE}>{t('home.link-fast-transfer')}</PrimaryLinkAtom>
        <PrimaryLinkAtom to={CONTAINER_ADMINISTRATION}>{t('home.link-container-management')}</PrimaryLinkAtom>
        <PrimaryLinkAtom to={ALERTS_HOME_PAGE}>{t('home.link-alerts')}</PrimaryLinkAtom>
        <PrimaryLinkAtom to={STORAGE_URL}>{t('home.storage-urls')}</PrimaryLinkAtom>
        <PrimaryLinkAtom to={RECEIPT_URL}>{t('home.receipt-urls')}</PrimaryLinkAtom>
        <PrimaryLinkAtom to={INVENTORY_WASTE_SCAN_ORIGIN}>{t('home.link-inventory-waste')}</PrimaryLinkAtom>
        <PrimaryLinkAtom to={INVENTORY_TRANSFER_URL}>{t('home.link-inventory-transfer')}</PrimaryLinkAtom>
        <PrimaryLinkAtom to={UNIVERSAL_SEARCH_URL}>{t('home.link-search')}</PrimaryLinkAtom>
        <PrimaryLinkAtom to={REVERSE_LOGISTICS_URL}>{t('home.link-reverse-logistics')}</PrimaryLinkAtom>
      </PageContentAtom>
    </>
  )
}
