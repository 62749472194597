import { AtlasBodySubtitle, AtlasColor, CardComponent, CardRow, SpacerAtom } from '@justomx/atlas-components'
import { FlexRowAtom } from 'components/atoms/FlexRow.atom'
import { DeleteIcon } from 'components/DeleteIcon'
import { formatWeight } from 'utils/product'

interface ListOfWeightsProps {
  detail: string
  items: number[]
  onDelete: (item: number) => void
}

const ListOfWeights = ({
  detail,
  items,
  onDelete
}: ListOfWeightsProps) => {
  return (
    <>
      <AtlasBodySubtitle>{detail}</AtlasBodySubtitle>
      <SpacerAtom height={10} />
      {
        items.map((it, i) => {
          return (
            <>
              <CardComponent key={`${i}_${it}`}>
                <CardRow>
                  <FlexRowAtom style={{ width: '100vw' }}>
                    <div style={{
                      flex: 1,
                      paddingRight: '1em'
                    }}>{formatWeight(it)}</div>
                    <div style={{
                      color: AtlasColor.Primary00,
                      cursor: 'pointer'
                    }}
                         onClick={() => {
                           onDelete(it)
                         }}>
                      <DeleteIcon />
                    </div>
                  </FlexRowAtom>
                </CardRow>
              </CardComponent>
            </>
          )
        })
      }
    </>
  )
}

export default ListOfWeights