import {RouteObject} from "react-router-dom";
import { ReverseLogisticsPage } from "./ReverseLogistics.page";
import { REVERSE_LOGISTICS_URL } from "./ReverseLogistics.navigator";
import { REVERSE_LOGISTICS_SCAN_PRODUCT_URL } from "./scan-product/ScanProduct.navigator";
import { ReverseLogisticsScanProductPage } from "./scan-product/ScanProduct.page";
import { REVERSE_LOGISTICS_REGISTER_URL } from "./register-product-quantity/RegisterProductQuantity.navigator";
import { ReverseLogisticsRegisterPage } from "./register-product-quantity/RegisterProductQuantity.page";
import { ReverseLogisticsRegisterWasteOrMissingPage } from "./register-waste-or-missing/RegisterWasteOrMissing.page";
import { REVERSE_LOGISTICS_REGISTER_WASTE_OR_MISSING_URL } from "./register-waste-or-missing/RegisterWasteOrMissing.navigator";
import { REVERSE_LOGISTICS_SCAN_DESTINATION_URL } from "./scan-destination/ScanDestination.navigator";
import { ScanDestinationPage } from "./scan-destination/ScanDestination.page";

export const reverseLogisticsRoutes: RouteObject[] = [
  {
    id: '(Logistica Inversa) - Scan',
    path: REVERSE_LOGISTICS_URL,
    element: <ReverseLogisticsPage/>
  },
  {
    id: '(Logistica Inversa) - Productos',
    path: REVERSE_LOGISTICS_SCAN_PRODUCT_URL,
    element: <ReverseLogisticsScanProductPage/>
  },
  {
    id: '(Logistica Inversa) - Registrar',
    path: REVERSE_LOGISTICS_REGISTER_URL,
    element: <ReverseLogisticsRegisterPage/>
  },
  {
    id: '(Logistica Inversa) - Registrar Merma o Faltante',
    path: REVERSE_LOGISTICS_REGISTER_WASTE_OR_MISSING_URL,
    element: <ReverseLogisticsRegisterWasteOrMissingPage/>
  },
  {
    id: '(Logistica Inversa) - Escanear destino',
    path: REVERSE_LOGISTICS_SCAN_DESTINATION_URL,
    element: <ScanDestinationPage/>
  },
]
