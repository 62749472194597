import { bffRestockingAxiosInstance } from '../../utils/bff-restocking.axios.instance'
import { AlertSuggestion, type AlertsWrapper } from './alerts.types'

export async function getAlertedContainersClient (): Promise<AlertsWrapper> {
  return await bffRestockingAxiosInstance()
    .get<AlertsWrapper>('/atlas/uploaded-containers')
    .then(({ data }) => data)
}

export async function getAlertsSuggestions (): Promise<AlertSuggestion[]> {
  return await bffRestockingAxiosInstance()
    .get<AlertSuggestion[]>(`/atlas/restocking-group-suggestions`)
    .then(({ data }) => data)
}
