import {analytics} from "./analytics.utils";
import * as receiptEvents from './receipt-analytics.events'

const VERSION = 'v1.0.1';

const errorHandler = e => {
  console.error("Error calling analytic event", e);
}

type LocationMovement = { origin: AnalyticsLocationAndContainer, destination: AnalyticsLocationAndContainer};

const generateId = () => {
  const url = URL.createObjectURL(new Blob())
  return url.substring(url.lastIndexOf('/') + 1)
}

type AnalyticsLocationAndContainer = {
  locationName: string
  locationType: string
  containerId?: string
  containerName?: string
}

type RestockParam = LocationMovement & {
  ean: string;
  count: number;
  alertLevel: string;
}

const restock = (e: RestockParam) => {
  analytics.track('User restocks', {
    version: VERSION,
    ean: e.ean,
    count: e.count,
    originContainerName: e.origin?.containerName,
    originLocationName: e.origin?.locationName,
    originLocationType: e.origin?.locationType,
    destinationContainerName: e.destination?.containerName,
    destinationLocationName: e.destination?.locationName,
    destinationLocationType: e.destination?.locationType,
    warehouse: e.destination?.locationName?.split('-')[0],
    zone: e.destination?.locationName?.split('-')[1],
    alertLevel: e.alertLevel,
  }).catch(errorHandler)
}

type MoveProductsParam = LocationMovement & {
  inventoryProducts: {productEan: string, stock: number}[];
}

const moveProducts  = (e: MoveProductsParam) => {
  const tx = generateId();
  e.inventoryProducts.forEach(x => {
    analytics.track('Move product', {
      version: VERSION,
      ean: x.productEan,
      count: x.stock,
      originContainerName: e.origin?.containerName,
      originLocationName: e.origin?.locationName,
      originLocationType: e.origin?.locationType,
      destinationContainerName: e.destination?.containerName,
      destinationLocationName: e.destination?.locationName,
      destinationLocationType: e.destination?.locationType,
      warehouse: e.destination?.locationName?.split('-')[0],
      zone: e.destination?.locationName?.split('-')[1],
      tx
    }).catch(errorHandler)
  })
}

const moveLooseProducts  = (e: MoveProductsParam) => {
  const tx = generateId();
  e.inventoryProducts.forEach(x => {
    analytics.track('Move loose product', {
      version: VERSION,
      ean: x.productEan,
      count: x.stock,
      originContainerName: e.origin?.containerName,
      originLocationName: e.origin?.locationName,
      originLocationType: e.origin?.locationType,
      destinationContainerName: e.destination?.containerName,
      destinationLocationName: e.destination?.locationName,
      destinationLocationType: e.destination?.locationType,
      warehouse: e.destination?.locationName?.split('-')[0],
      zone: e.destination?.locationName?.split('-')[1],
      tx
    }).catch(errorHandler)
  })
}

const moveContainer = (e: LocationMovement) => {
  analytics.track('Move container', {
    version: VERSION,
    originContainerName: e.origin?.containerName,
    originLocationName: e.origin?.locationName,
    originLocationType: e.origin?.locationType,
    destinationContainerName: e.destination?.containerName,
    destinationLocationName: e.destination?.locationName,
    destinationLocationType: e.destination?.locationType,
    warehouse: e.destination?.locationName?.split('-')[0],
    zone: e.destination?.locationName?.split('-')[1],
  }).catch(errorHandler)
}

const storage = (e: LocationMovement) => {
  analytics.track('Storage container', {
    version: VERSION,
    originContainerName: e.origin?.containerName,
    originLocationName: e.origin?.locationName,
    originLocationType: e.origin?.locationType,
    destinationContainerName: e.destination?.containerName,
    destinationLocationName: e.destination?.locationName,
    destinationLocationType: e.destination?.locationType,
    warehouse: e.destination?.locationName?.split('-')[0],
    zone: e.destination?.locationName?.split('-')[1],
  }).catch(errorHandler)
}

const error = (e: Record<string, string>) => {
  analytics.track('Error', {
    version: VERSION,
    ...e,
  }).catch(errorHandler)
}

const lowerContainer = (e: LocationMovement) => {
  analytics.track('Lower container', {
    version: VERSION,
    originContainerName: e.origin?.containerName,
    originLocationName: e.origin?.locationName,
    originLocationType: e.origin?.locationType,
    destinationContainerName: e.destination?.containerName,
    destinationLocationName: e.destination?.locationName,
    destinationLocationType: e.destination?.locationType,
    warehouse: e.destination?.locationName?.split('-')[0],
    zone: e.destination?.locationName?.split('-')[1],
  }).catch(errorHandler)
}

const upliftContainer = (e: LocationMovement) => {
  analytics.track('Uplift container', {
    version: VERSION,
    originContainerName: e.origin?.containerName,
    originLocationName: e.origin?.locationName,
    originLocationType: e.origin?.locationType,
    destinationContainerName: e.destination?.containerName,
    destinationLocationName: e.destination?.locationName,
    destinationLocationType: e.destination?.locationType,
  }).catch(errorHandler)
}

type CreateProductProp = {
  location: AnalyticsLocationAndContainer,
  ean: string,
  count: number
};
const createProduct = (e: CreateProductProp) => {
  analytics.track('Create product in inventory', {
    version: VERSION,
    destinationContainerName: e.location?.containerName,
    destinationLocationName: e.location?.locationName,
    destinationLocationType: e.location?.locationType,
    warehouse: e.location?.locationName?.split('-')[0],
    zone: e.location?.locationName?.split('-')[1],
    ean: e.ean,
    count: e.count
  }).catch(errorHandler)
}

interface CreateInventoryWasteReportProps { 
  reportData: {
    productEan: string;
    productSku: string;
    productName: string;
    productImage: string;
    quantity: number;
    locationName: string;
    containerName?: string;
    reason: string[];
  }
}

const createInventoryWasteReport  = (e: CreateInventoryWasteReportProps) => {
  analytics.track('Create invenotry waste report', {
    version: VERSION,
    product: {
      ean: e.reportData.productEan,
      sku: e.reportData.productSku,
      name: e.reportData.productName,
      image: e.reportData.productImage,
    },
    quantity: e.reportData.quantity,
    reason: e.reportData.reason,
    date: new Date().toISOString(),
  }).catch(errorHandler)
}

type AdjustInventoryProp = {
  ean: string;
  count: number;
  location: string
};
export const adjustInventory = (e: AdjustInventoryProp) => {
  analytics.track('Adjust inventory', {
    location: e.location,
    ean: e.ean,
    count: e.count
  }).catch(errorHandler)
}

type UnpickItemProps = {
  destinationLocationName: string;
  destinationLocationType: string
  ean: string;
  orderId: string;
  productName: string;
  quantity: number;
  sku: string;
  supply: string;
  warehouse: string;
  zone: string;
  priorityLevel: string;
};

const UnpickItem = (e: UnpickItemProps) => {
  analytics.track('reverse logistics - Unpick item', {
    destinationLocationName: e.destinationLocationName,
    destinationLocationType: e.destinationLocationType,
    ean: e.ean,
    orderId: e.orderId,
    productName: e.productName,
    quantity: e.quantity,
    sku: e.sku,
    supply: e.supply,
    VERSION, 
    warehouse: e.warehouse, 
    zone: e.zone,
    priorityLevel: e.priorityLevel
  }).catch(errorHandler)
} 

type RegisterWasteOrMissingProps = {
  ean: string;
  orderId: string;
  productName: string;
  quantity: number;
  sku: string;
  supply: string;
  warehouse: string;
  zone: string;
  priorityLevel: string;
};

const RegisterWaste = (e: RegisterWasteOrMissingProps) => {
  analytics.track('reverse logistics - Waste', {
    ean: e.ean,
    orderId: e.orderId,
    productName: e.productName,
    quantity: e.quantity,
    sku: e.sku,
    supply: e.supply,
    VERSION, 
    warehouse: e.warehouse, 
    zone: e.zone,
    priorityLevel: e.priorityLevel
  }).catch(errorHandler)
} 

const RegisterMissing = (e: RegisterWasteOrMissingProps) => {
  analytics.track('reverse logistics - Missing', {
    ean: e.ean,
    orderId: e.orderId,
    productName: e.productName,
    quantity: e.quantity,
    sku: e.sku,
    supply: e.supply,
    VERSION, 
    warehouse: e.warehouse, 
    zone: e.zone,
    priorityLevel: e.priorityLevel
  }).catch(errorHandler)
} 
export const Analytics = {
  restock,
  storage,
  error,
  traslation: {
    moveProducts,
    moveLooseProducts,
    moveContainer,
  },
  forklift: {
    lowerContainer,
    upliftContainer,
  },
  inventoryFix: {
    createProduct,
    adjustInventory,
  },
  inventoryWaste: {
    createInventoryWasteReport,
  },
  receipt: receiptEvents,
  reverseLogistics: {
    UnpickItem,
    RegisterWaste,
    RegisterMissing
  }
}