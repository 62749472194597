import { ScanResponse } from 'client/scanner/scanner.types'

export function validateOrigin (origin: ScanResponse) {

  if (origin.location && !['Storage','PreStorage','Rack'].includes(origin.location.type)) {
    throw Error('O-3: No se puede escanear una ubicación de Picking')
  }

  return origin
}
