import { AlertIndicatorAtom } from 'components/atoms/AlertIndicator.atom'
import { LocationInfo, type LocationAndContainer } from '../../../types/model'
import {AtlasBodySubtitle, AtlasBodyTitle, AtlasColor, CardRow, Icon, Icons} from '@justomx/atlas-components'
import { FlexRowAtom } from 'components/atoms/FlexRow.atom'

interface LocationAndContainerMoleculeProp {
  data: Omit<LocationInfo, 'containersInLocation'>
  focus?: 'location' | 'container'
  showAlerts?: boolean
}

export const LocationAndContainerMolecule = ({ data, focus='container', showAlerts=true }: LocationAndContainerMoleculeProp) => {
  const { location, container, alerts } = data
  const isLocationFocus = focus === 'location';
  const title = isLocationFocus ? location : container;
  const subtitle = isLocationFocus ? container : location;
  const titleIcon = isLocationFocus ? Icons.IcUbicacionIcon : Icons.IcPallet;
  const subtitleIcon = isLocationFocus ? Icons.IcPallet : Icons.IcUbicacionIcon;
  return (
    <>
      {title &&
        <CardRow style={{justifyContent: 'space-between'}}>
          <FlexRowAtom style={{ gap: 8}}>
            <Icon src={titleIcon} size={18}/>
            <AtlasBodyTitle 
              color={AtlasColor.Neutral00}
              data-location={title}
            >
              {title}
            </AtlasBodyTitle>
          </FlexRowAtom>
          {alerts && showAlerts && 
            <FlexRowAtom style={{ gap: 8}}>
              {/* TODO: show urgent alerts when backend implementation is ready */}
              {/* <AlertIndicatorAtom type='urgent'>{alerts.urgent}</AlertIndicatorAtom> */}
              <AlertIndicatorAtom type='critical'>{alerts.critical}</AlertIndicatorAtom>
              <AlertIndicatorAtom type='warning'>{alerts.warning}</AlertIndicatorAtom>
            </FlexRowAtom>
          }
        </CardRow>
      }
      {subtitle &&
        <CardRow >
          <Icon src={subtitleIcon} size={18}/>
          <AtlasBodySubtitle 
            color={AtlasColor.Neutral20}
            data-container={subtitle}
            >
            {subtitle}
          </AtlasBodySubtitle>
        </CardRow>
      }
    </>
  )
}
